const Terms = () => {
  return (
    <div>
      <div className="small my-3">最后更新：2023年12月12日</div>
      <div>
        <ol>
          <li>
            <h4>简介</h4>
            <p>
              欢迎来到麦塔莫达（"公司"、"我们"、"我们的"、"我们"）！这些服务条款（"条款"、"服务条款"）管理您对我们位于https://cn.metamoda.co 的网页和我们的移动应用程序麦塔莫达（统称或单独称为"服务"）的使用，这些服务由麦塔莫达运营。我们的隐私政策也管理您对我们服务的使用，并解释了我们如何收集、保护和披露因您使用我们网页而产生的信息。请在此处阅读：<a href="./legal#privacyPolicy">https://cn.metamoda.co/legal#privacyPolicy</a>。您与我们的协议包括这些条款和我们的隐私政策（"协议"）。您承认您已阅读并理解协议，并同意受其约束。如果您不同意（或无法遵守）协议，那么您可能不能使用服务。这些条款适用于所有希望访问或使用服务的访客、用户和其他人。通过使用本网站，您同意这些条款，这些条款可能会随时更新，无需通知，由麦塔莫达自行决定。
            </p>
          </li>
          <li>
            <h4>通讯</h4>
            <p>
              通过在我们的服务上创建账户，您同意订阅新闻通讯、营销或促销材料以及我们可能发送的其他信息。但是，您可以通过点击取消订阅链接或发送电子邮件来选择不接收我们的任何或所有这些通信。
            </p>
          </li>
          <li>
            <h4>购买</h4>
            <p>
              如果您希望购买通过服务提供的任何产品或服务（"购买"），您可能会被要求提供与您的购买相关的某些信息，包括但不限于您的信用卡号码、信用卡到期日期、您的账单地址和您的送货信息。您表示并保证：(i) 您有合法权利使用任何信用卡或其他支付方式与任何购买相关；以及 (ii) 您提供给我们的信息是真实、正确和完整的。我们可能会使用第三方服务来促进支付和完成购买。通过提交您的信息，您授予我们根据我们的隐私政策将信息提供给这些第三方的权利。我们保留随时因各种原因拒绝或取消您的订单的权利，包括但不限于：产品或服务可用性、产品或服务描述或价格中的错误、您订单中的错误或其他原因。如果怀疑存在欺诈或未经授权或非法交易，我们保留拒绝或取消您的订单的权利。
            </p>
          </li>
          <li>
            <h4>竞赛、抽奖和促销</h4>
            <p>
              通过服务提供的任何竞赛、抽奖或其他促销活动（统称为"促销活动"）可能受到与这些服务条款分开的规则约束。如果您参与任何促销活动，请查看适用的规则以及我们的隐私政策。如果促销活动的规则与这些服务条款冲突，将适用促销活动规则。
            </p>
          </li>
          <li>
            <h4>订阅</h4>
            <p>
              服务的某些部分是按订阅基础收费的（"订阅"）。您将提前按周期性基础收费（"计费周期"）。计费周期根据您购买订阅时选择的订阅计划类型设置为每月或每年。在每个计费周期结束时，除非您取消或麦塔莫达取消，否则您的订阅将在完全相同的条件下自动续订。您可以通过您的在线账户管理页面或联系麦塔莫达客户支持团队来取消您的订阅续订。需要有效的支付方式，包括信用卡或PayPal，来处理您订阅的付款。您应向麦塔莫达提供准确完整的账单信息，包括全名、地址、州、邮政编码、电话号码和有效的支付方式信息。通过提交此类支付信息，您自动授权麦塔莫达向任何此类支付工具收取您账户产生的所有订阅费用。如果自动计费因任何原因失败，麦塔莫达将发出电子发票，表明您必须在某个截止日期之前手动进行全额付款，对应发票上指示的计费期。
            </p>
          </li>
          <li>
            <h4>费用变更</h4>
            <p>
              麦塔莫达保留随时更改订阅费用的权利。任何订阅费用变更将在当前计费周期结束后的下一个计费周期生效。我们将通过电子邮件、通过我们的网站发布通知或通过我们的服务内的通知，在费用变更生效前至少30天通知您任何费用变更。继续使用我们的服务即表示您同意支付修改后的订阅费用金额。
            </p>
          </li>
          <li>
            <h4>退款</h4>
            <p>
              某些退款情况可能会被考虑, 并由麦塔莫达自行决定。我们不提供部分月份订阅的退款。
            </p>
          </li>
          <li>
            <h4>内容</h4>
            <p>
              我们的服务允许您发布、链接、存储、共享和以其他方式提供某些信息、文本、图形、视频或其他材料（"内容"）。您对使用服务发布、链接、存储、共享和以其他方式提供的任何内容负责。通过使用服务发布内容，您保证并表示您拥有或已获得使用和授权我们使用内容的所有必要权利，包括根据这些条款授予的许可。您同意您发布的任何内容不会侵犯任何第三方的权利。我们保留随时删除任何内容的权利，恕不另行通知。麦塔莫达对您或任何第三方发布的任何内容不承担责任。
            </p>
          </li>
          <li>
            <h4>禁止使用</h4>
            <p>
              您只能将服务用于合法目的，并根据适用法律和法规使用。您同意不会使用服务：
            </p>
            <ol>
              <li>以任何非法方式或违反任何地方、州、国家或国际法律或法规的方式。</li>
              <li>出于剥削、伤害或试图剥削或伤害未成年人的目的。</li>
              <li>发送、故意接收、上传、下载、使用或重复使用任何不符合内容标准的材料。</li>
              <li>传输或促使发送任何未经请求或未经授权的广告或促销材料或任何其他形式的类似招揽（垃圾邮件）。</li>
              <li>故意传输任何数据、发送或上传任何包含病毒、特洛伊木马、蠕虫、定时炸弹、键盘记录器、间谍软件、广告软件或任何其他有害程序或类似计算机代码的材料，旨在对任何计算机软件或硬件的操作产生不利影响。</li>
            </ol>
            <p>此外，您同意不会：</p>
            <ol>
              <li>使用服务以任何方式损害或损坏服务或干扰任何其他方使用服务，包括他们访问任何功能的能力。</li>
              <li>使用任何机器人、蜘蛛或其他自动设备、过程或方式访问服务以用于任何目的，包括监视或复制服务上的任何材料。</li>
              <li>使用任何手动过程监视或复制服务上的任何材料，或出于任何其他未经授权的目的，未经我们事先书面同意。</li>
              <li>以任何方式使用服务可能会禁用、过载、损坏或损害服务或干扰任何其他方使用服务，包括他们参与实时活动的能力。</li>
              <li>访问或尝试访问不适合您的服务部分或功能，或绕过我们用来防止或限制访问服务的任何措施。</li>
              <li>尝试规避我们可能使用的任何内容过滤技术，或尝试访问未通过服务界面明确提供给您的任何服务或功能。</li>
              <li>收集或存储其他用户的任何个人信息，未经他们明确许可。</li>
              <li>冒充或试图冒充公司、公司员工、另一个用户或任何其他人或实体。</li>
              <li>以任何可能创建责任或导致麦塔莫达失去（全部或部分）我们的互联网服务提供商或其他供应商的服务的方式使用服务。</li>
            </ol>
          </li>
          <li>
            <h4>分析</h4>
            <p>
              我们可能会使用第三方服务提供商来监控和分析我们服务的使用情况。
            </p>
          </li>
          <li>
            <h4>知识产权</h4>
            <p>
              服务及其原始内容（不包括您提供的内容）、功能和功能是并将保持麦塔莫达的专有财产。服务受版权、商标和其他法律保护。我们的商标和商业外观不得用于与服务无关的任何产品或服务，特别是不得以可能导致客户混淆或以任何方式诋毁或贬低麦塔莫达的方式使用。
            </p>
          </li>
          <li>
            <h4>版权政策</h4>
            <p>
              我们尊重他人的知识产权，并要求我们的用户也这样做。我们将回应并终止重复侵犯或被指控重复侵犯版权或其他知识产权的用户的账户。
            </p>
          </li>
          <li>
            <h4>终止</h4>
            <p>
              我们可以在不事先通知的情况下，因任何原因立即终止或暂停您对服务的访问，包括但不限于违反这些条款。所有关于终止的规定在终止后仍然有效，包括但不限于所有权规定、保证免责声明、赔偿和责任限制。
            </p>
          </li>
          <li>
            <h4>赔偿</h4>
            <p>
              您同意赔偿、辩护和使麦塔莫达及其许可方和服务提供商免受任何索赔、责任、损害、判决、奖励、损失、成本、费用或开支（包括合理的律师费）的影响，这些索赔、责任、损害、判决、奖励、损失、成本、费用或开支是由于您违反这些条款而引起的。
            </p>
          </li>
          <li>
            <h4>责任限制</h4>
            <p>
              在任何情况下，麦塔莫达、其董事、员工、合作伙伴、代理人、供应商或附属机构均不对任何间接的、偶然的、特殊的、后果性的或惩罚性的损害负责，包括但不限于利润损失、数据损失、使用损失、商誉损失或其他无形损失，这些损害是由于(i)您访问或使用或无法访问或使用服务；(ii)服务上的任何行为或内容；(iii)通过服务获得的任何内容；(iv)未经授权访问、使用或更改您的传输或内容。在任何情况下，麦塔莫达的责任总额不得超过您在过去六个月内为服务支付的金额，或50美元，以较高者为准。
            </p>
          </li>
          <li>
            <h4>免责声明</h4>
            <p>
              您使用服务的风险完全由您自己承担。服务按"原样"和"可用"的基础提供，不作任何明示或暗示的保证。麦塔莫达明确否认任何种类的所有保证，无论是明示的还是暗示的，包括但不限于适销性、特定用途适用性和非侵权的暗示保证。麦塔莫达不对内容的准确性、可能的结果或服务的可靠性做出任何保证或陈述。
            </p>
          </li>
          <li>
            <h4>排除</h4>
            <p>
              一些司法管辖区不允许排除某些保证或责任限制。因此，上述某些限制可能不适用于您。
            </p>
          </li>
          <li>
            <h4>管辖法律</h4>
            <p>
            这些条款应受美国加利福尼亚州法律管辖和解释，不考虑其冲突法规定。我们未能执行这些条款的任何权利或规定不会被视为对这些权利的放弃。如果这些条款的任何规定被法院认定为无效或不可执行，其余规定将继续有效。这些条款构成了您与我们之间关于服务的完整协议，并取代了我们之间可能存在的任何先前协议。
        </p>
      </li>
      <li>
        <h4>服务变更</h4>
        <p>
          我们保留自行决定随时撤回或修改我们的服务，以及我们通过服务提供的任何服务或材料的权利，恕不另行通知。如果由于任何原因，服务的全部或任何部分在任何时间或任何期间不可用，我们不承担责任。我们可能会不时限制用户（包括注册用户）访问服务的某些部分或整个服务。
        </p>
      </li>
      <li>
        <h4>条款修改</h4>
        <p>
          我们可能随时通过在本网站上发布修改后的条款来修改条款。您有责任定期查看这些条款。您在修订条款发布后继续使用平台意味着您接受并同意这些变更。您应经常查看此页面，以了解任何变更，因为它们对您具有约束力。如果您不同意新条款，您将不再被授权使用服务。
        </p>
      </li>
      <li>
        <h4>豁免和可分割性</h4>
        <p>
          公司对条款中规定的任何条款或条件的豁免不应被视为对该条款或条件的进一步或持续豁免，或对任何其他条款或条件的豁免，公司未能主张条款下的任何权利或规定不构成对该权利或规定的豁免。如果条款的任何规定被具有管辖权的法院或其他法庭认定为因任何原因无效、非法或不可执行，则应消除或限制该规定，以使条款的其余规定继续完全有效。
        </p>
      </li>
      <li>
        <h4>Alpha</h4>
        <p>
          麦塔莫达目前仍以"alpha"形式提供功能（"Alpha服务"）。通过接受这些条款和/或使用Alpha服务，您理解并承认Alpha服务正作为alpha版本提供，并按"原样"或"可用"基础提供。因此，这些alpha服务可能包含错误、缺陷和其他问题。您承担与使用alpha服务相关的所有成本和风险，包括但不限于任何互联网访问费用、备份费用、因使用您的设备和外围设备而产生的成本，以及对任何设备、软件、信息或数据的任何损害。我们网站上提供的任何信息、服务或材料均不构成，也不意图构成法律、财务、税务、投资或其他建议，您不应根据本网站提供的任何信息、服务或材料采取行动或避免采取行动。我们网站上的所有内容都是一般性质的信息，不针对任何用户的独特情况。此外，我们没有义务为alpha服务提供任何维护、技术或其他支持。强烈建议您就所有法律、财务、税务和投资相关问题咨询您自己的法律、财务、税务和其他顾问。麦塔莫达目前不提供任何二级交易或首次公开募股，直到我们通过SEC、FINRA和我们的运营合作伙伴完全合规。我们网站上展示的所有代表和人物仅用于说明目的，与真实人物和企业无关。
        </p>
      </li>
      <li>
        <h4>确认</h4>
        <p>通过使用服务或我们提供的其他服务，您承认您已阅读这些服务条款并同意受其约束。</p>
      </li>
      <li>
        <h4>联系我们</h4>
        <p>
          请发送您的反馈、评论、技术支持请求：通过电子邮件：info@metamoda.co。通过访问我们网站上的此页面：<a href="https://cn.metamoda.co">https://cn.metamoda.co</a>。
        </p>
      </li>
    </ol>
  </div>
</div>
  );
};

export default Terms;