const Privacy = () => {
  return (
    <div>
      <div className="small my-3">最后更新：2023年12月12日</div>
      <div>
        <ol>
          <li>
            <h4>简介</h4>
            <p>
              欢迎来到麦塔莫达，麦塔莫达（"我们"或"我们的"）运营<a href="https://cn.metamoda.co">https://cn.metamoda.co</a>网站和麦塔莫达移动应用程序（以下简称"服务"）。我们的隐私政策规定了您访问<a href="https://cn.metamoda.co">https://cn.metamoda.co</a>和麦塔莫达移动应用程序的行为，并解释了我们如何收集、保护和披露因您使用我们的服务而产生的信息。我们使用您的数据来提供和改进服务。通过使用服务，您同意按照本政策收集和使用信息。除非本隐私政策另有定义，否则本隐私政策中使用的术语与我们的条款和条件中的含义相同。我们的条款和条件（"条款"）规定了我们所有服务的使用，并与隐私政策一起构成您与我们的协议（"协议"）。
            </p>
          </li>
          <li>
            <h4>定义</h4>
            <ol>
              <li>
                服务是指由麦塔莫达运营的<a href="https://cn.metamoda.co">https://cn.metamoda.co</a>网站和麦塔莫达移动应用程序
              </li>
              <li>个人数据是指可以识别出生者身份的数据（或从这些数据和我们拥有或可能拥有的其他信息中识别出的数据）。</li>
              <li>使用数据是指自动收集的数据，既可能由服务的使用产生，也可能来自服务基础设施本身（例如，页面访问的持续时间）。</li>
              <li>cookies是存储在您设备（计算机或移动设备）上的小文件</li>
              <li>
                数据控制者是指（单独或与他人共同或共同）确定处理任何个人数据的目的和方式的自然人或法人。就本隐私政策而言，我们是您数据的数据控制者。
              </li>
              <li>
                数据处理者（或服务提供商）是指代表数据控制者处理数据的任何自然人或法人。我们可能使用各种服务提供商的服务，以更有效地处理您的数据。
              </li>
              <li>数据主体是指作为个人数据主体的任何在世个人。</li>
              <li>用户是指使用我们服务的个人。用户对应于作为个人数据主体的数据主体。</li>
            </ol>
          </li>
          <li>
            <h4>收集的数据类型</h4>
            <p>
              在使用我们的服务时，我们可能会要求您向我们提供某些可用于联系或识别您身份的个人身份信息（"个人数据"）。个人身份信息可能包括但不限于：
            </p>
            <ol>
              <li>电子邮件地址</li>
              <li>名字和姓氏</li>
              <li>电话号码</li>
              <li>地址、州、省、邮政编码、城市</li>
              <li>Cookies和使用数据</li>
            </ol>
            <p>
              我们可能会使用您的个人数据与您联系，发送新闻通讯、营销或促销材料以及其他可能对您感兴趣的信息。您可以选择不接收我们的任何或所有这些通信，方法是点击取消订阅链接或发送电子邮件至 info@metamoda.co。
            </p>
          </li>
          <li>
            <h5>使用数据</h5>
            <p>
              我们还可能收集您的浏览器在您访问我们的服务时发送的信息，或当您通过移动设备访问服务时（"使用数据"）。此使用数据可能包括您计算机的互联网协议地址（例如IP地址）、浏览器类型、浏览器版本、您访问的我们服务的页面、您访问的时间和日期、在这些页面上花费的时间、唯一设备标识符和其他诊断数据。当您通过移动设备访问服务时，此使用数据可能包括您使用的移动设备类型、您的移动设备唯一ID、您的移动设备的IP地址、您的移动操作系统、您使用的移动互联网浏览器类型、唯一设备标识符和其他诊断数据。
            </p>
          </li>
          <li>
            <h5>位置数据</h5>
            <p>
              如果您允许，我们可能会使用和存储有关您位置的信息（"位置数据"）。我们使用这些数据来提供我们服务的功能，改进和定制我们的服务。您可以随时通过设备设置启用或禁用使用我们服务时的位置服务。
            </p>
          </li>
          <li>
            <h5>跟踪Cookies数据</h5>
            <p>
              我们使用cookies和类似的跟踪技术来跟踪我们服务上的活动，并保存某些信息。cookies是包含少量数据的文件，可能包含匿名唯一标识符。cookies从网站发送到您的浏览器并存储在您的设备上。还使用其他跟踪技术，如信标、标签和脚本来收集和跟踪信息，以改进和分析我们的服务。您可以指示您的浏览器拒绝所有cookies或指示何时发送cookie。但是，如果您不接受cookies，您可能无法使用我们服务的某些部分。
            </p>
            <p>我们使用的Cookies示例：</p>
            <ol>
              <li>会话Cookies：我们使用会话Cookies来运营我们的服务。</li>
              <li>偏好Cookies：我们使用偏好Cookies来记住您的偏好和各种设置。</li>
              <li>安全Cookies：我们出于安全目的使用安全Cookies。</li>
              <li>广告Cookies：广告Cookies用于向您提供可能与您相关的广告。</li>
            </ol>
            <h5>其他数据</h5>
            <p>
              在使用我们的服务时，我们还可能收集以下信息：性别、年龄、出生日期、出生地、护照详细信息、公民身份、居住地登记和实际地址、电话号码（工作、手机）、教育文件详细信息、资格、专业培训、雇佣协议、保密协议、奖金和薪酬信息、婚姻状况、家庭成员、社会保障（或其他纳税人识别）号码、办公地点和其他数据。
            </p>
          </li>
          <li>
            <h4>数据使用</h4>
            <p>麦塔莫达将收集的数据用于各种目的：</p>
            <ol>
              <li>提供和维护我们的服务；</li>
              <li>通知您有关我们服务的变更；</li>
              <li>允许您在选择时参与我们服务的互动功能；</li>
              <li>提供客户支持；</li>
              <li>收集分析或有价值的信息，以便我们改进我们的服务；</li>
              <li>监控我们服务的使用情况；</li>
              <li>检测、预防和解决技术问题；</li>
              <li>履行您提供它的任何其他目的；</li>
              <li>履行我们的义务并执行我们与您之间签订的任何合同所产生的权利，包括计费和收款；</li>
              <li>向您提供有关您的账户和/或订阅的通知，包括到期和续订通知、电子邮件说明等；</li>
              <li>
                向您提供与您已经购买或询问的类似的其他商品、服务和活动的新闻、特别优惠和一般信息，除非您已选择不接收此类信息；
              </li>
              <li>以我们在您提供信息时可能描述的任何其他方式；</li>
              <li>用于任何其他经您同意的目的。</li>
            </ol>
          </li>
          <li>
            <h4>数据保留</h4>
            <p>
              我们将仅在本隐私政策中规定的目的所需的时间内保留您的个人数据。我们将在必要的范围内保留和使用您的个人数据，以遵守我们的法律义务（例如，如果我们需要保留您的数据以遵守适用法律）、解决争议并执行我们的法律协议和政策。
            </p>
            <p>
              我们还将保留使用数据用于内部分析目的。使用数据通常保留较短的时间，除非这些数据用于加强安全性或改进我们服务的功能，或者我们在法律上有义务保留这些数据更长的时间。
            </p>
          </li>
          <li>
            <h4>数据传输</h4>
            <p>
              您的信息，包括个人数据，可能会被传输到 - 并保存在 - 位于您所在州、省、国家或其他政府管辖区以外的计算机上，这些地方的数据保护法可能与您所在管辖区的法律不同。如果您位于美国以外并选择向我们提供信息，请注意我们将数据（包括个人数据）传输到美国并在那里处理。您同意本隐私政策，然后提交此类信息，即表示您同意该转移。
            </p>
            <p>
              麦塔莫达将采取一切合理必要的步骤，确保您的数据得到安全处理，并符合本隐私政策，除非有适当的控制措施（包括您的数据和其他个人信息的安全性），否则不会将您的个人数据传输到组织或国家。
            </p>
          </li>
          <li>
            <h4>数据披露</h4>
            <p>我们可能会披露我们收集的或您提供的个人信息：</p>
            <ol>
              <li>
                <h5>执法披露</h5>
                <p>在某些情况下，如果法律要求或响应公共当局的有效请求，我们可能需要披露您的个人数据。</p>
              </li>
              <li>
                <h5>业务交易</h5>
                <p>如果我们或我们的子公司参与合并、收购或资产出售，您的个人数据可能会被转移。</p>
              </li>
              <li>
                <h5>其他情况。我们也可能披露您的信息：</h5>
                <ol>
                  <li>向我们的子公司和关联公司；</li>
                  <li>向我们用来支持我们业务的承包商、服务提供商和其他第三方；</li>
                  <li>为了实现您提供它的目的；</li>
                  <li>为了在我们的网站上包含您公司的标志；</li>
                  <li>为了我们在您提供信息时披露的任何其他目的；</li>
                  <li>在任何其他情况下经您同意；</li>
                  <li>如果我们认为披露是必要或适当的，以保护公司、我们的客户或他人的权利、财产或安全。</li>
                </ol>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4>数据安全</h4>
            <p>
              您的数据安全对我们很重要，但请记住，没有任何通过互联网传输的方法或电子存储方法是100%安全的。虽然我们努力使用商业上可接受的方式来保护您的个人数据，但我们不能保证其绝对安全。
            </p>
          </li>
          <li>
            <h4>根据通用数据保护条例（GDPR）您的数据保护权利</h4>
            <p>
              如果您是欧盟（EU）和欧洲经济区（EEA）的居民，您拥有某些数据保护权利，这些权利受GDPR保护。 - 更多信息请参见<a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a>
              我们旨在采取合理步骤，允许您更正、修改、删除或限制使用您的个人数据。如果您希望了解我们持有关于您的个人数据，并希望将其从我们的系统中删除，请发送电子邮件至 info@metamoda.co。在某些情况下，您拥有以下数据保护权利：
            </p>
            <ol>
              <li>访问、更新或删除我们拥有的关于您的信息的权利；</li>
              <li>纠正权。如果该信息不准确或不完整，您有权要求纠正您的信息；</li>
              <li>反对权。您有权反对我们处理您的个人数据；</li>
              <li>限制权。您有权要求我们限制处理您的个人信息；</li>
              <li>数据可携带权。您有权以结构化、机器可读和常用格式获得您的个人数据的副本</li>
              <li>撤回同意权。在我们依赖您的同意处理您的个人信息的情况下，您还有权随时撤回您的同意；</li>
            </ol>
            <p>
              请注意，在回应此类请求之前，我们可能会要求您验证您的身份。请注意，如果没有某些必要的数据，我们可能无法提供服务。您有权向数据保护机构投诉我们收集和使用您个人数据的行为。有关更多信息，请联系您所在欧洲经济区（EEA）的当地数据保护机构。
            </p>
          </li>
          <li>
            <h4>根据加州在线隐私保护法（CalOPPA）您的数据保护权利</h4>
            <p>
              CalOPPA是全国第一个要求商业网站和在线服务发布隐私政策的州法律。该法律的范围远远超出了加利福尼亚州，要求在美国（可能还包括世界其他地方）运营收集加利福尼亚州消费者个人身份信息的网站的个人或公司在其网站上发布醒目的隐私政策，准确说明正在收集的信息以及与之共享信息的个人，并遵守此政策。 - 更多信息请参见：
              <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/ ">https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/</a>
              根据CalOPPA，我们同意以下内容：
            </p>
            <ol>
              <li>用户可以匿名访问我们的网站；</li>
              <li>我们的隐私政策链接包含"隐私"一词，可以在我们网站主页上指定的页面上轻松找到；</li>
              <li>用户将在我们的隐私政策页面上收到任何隐私政策变更的通知；</li>
              <li>用户可以通过发送电子邮件至 info@metamoda.co 来更改他们的个人信息。</li>
            </ol>
            <p>我们关于"请勿跟踪"信号的政策：</p>
            <p>
              我们尊重"请勿跟踪"信号，当"请勿跟踪"浏览器机制生效时，我们不会跟踪、植入cookies或使用广告。"请勿跟踪"是一种偏好设置，您可以在网络浏览器中设置，以通知网站您不希望被跟踪。您可以通过访问网络浏览器的偏好设置或设置页面来启用或禁用"请勿跟踪"。
            </p>
          </li>
          <li>
            <h4>根据加州消费者隐私法（CCPA）您的数据保护权利</h4>
            <p>如果您是加利福尼亚州居民，您有权了解我们收集关于您的哪些数据，要求删除您的数据，并不出售（共享）这些数据。要行使您的数据保护权利，您可以提出某些请求并要求我们：</p>
            <ol>
              <li>
                <p>我们拥有关于您的哪些个人信息。如果您提出此请求，我们将返回给您：</p>
                <ol>
                  <li>我们收集的关于您的个人信息的类别。</li>
                  <li>我们收集您个人信息的来源类别。</li>
                  <li>收集或出售您个人信息的业务或商业目的。</li>
                  <li>我们与之共享个人信息的第三方类别。</li>
                  <li>我们收集的关于您的具体个人信息。</li>
                  <li>如果我们出售了您的个人信息，我们会告知您我们出售的个人信息类别的列表，以及我们出售给的任何其他公司的类别。如果我们没有出售您的个人信息，我们会告知您这一事实。</li>
                  <li>我们出于商业目的披露的个人信息类别的列表，以及我们与之共享的任何其他公司的类别。</li>
                </ol>
                <p>
                  请注意，您有权在连续十二个月内最多两次要求我们提供此信息。当您提出此请求时，所提供的信息可能仅限于我们在过去12个月内收集的关于您的个人信息。
                </p>
              </li>
              <li>
                删除您的个人信息。如果您提出此请求，我们将从我们的记录中删除截至您请求日期我们持有的关于您的个人信息，并指示任何服务提供商也这样做。在某些情况下，删除可能通过去识别化信息来完成。如果您选择删除您的个人信息，您可能无法使用某些需要您的个人信息才能运作的功能。
              </li>
              <li>
                停止出售您的个人信息。我们不会出售您的个人信息以获得金钱对价。然而，在某些情况下，根据加利福尼亚法律，将个人信息转移给第三方或在我们的公司家族内部转移而不获得金钱对价可能被视为"出售"。如果您提交停止出售您个人信息的请求，我们将停止进行此类转移。如果您是加利福尼亚州居民，要选择退出出售您的个人信息，请点击我们主页底部的"不要出售我的个人信息"以提交您的请求。请注意，如果您要求我们删除或停止出售您的数据，这可能会影响您与我们的体验，您可能无法参与某些需要使用您的个人信息才能运作的程序或会员服务。但在任何情况下，我们都不会因为您行使权利而歧视您。要行使上述加利福尼亚数据保护权利，请通过以下方式之一发送您的请求：通过电子邮件：info@metamoda.co 通过访问我们网站上的此页面：
                <a href="https://cn.metamoda.co">https://cn.metamoda.co</a>
              </li>
            </ol>
            <p>
              上述描述的您的数据保护权利受CCPA（加利福尼亚消费者隐私法的缩写）保护。要了解更多信息，请访问官方加利福尼亚立法信息网站（
              <a href="http://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375">http://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375</a>）。CCPA于2020年1月1日生效。
            </p>
          </li>
          <li>
            <h4>服务提供商</h4>
            <p>
              我们可能雇用第三方公司和个人来促进我们的服务（"服务提供商"），代表我们提供服务，执行与服务相关的服务或协助我们分析我们的服务使用情况。这些第三方只能访问您的个人数据以代表我们执行这些任务，并且有义务不将其用于任何其他目的。
            </p>
          </li>
          <li>
            <h4>分析</h4>
            <p>我们可能使用第三方服务提供商来监控和分析我们服务的使用情况。</p>
            <h5>Google Analytics</h5>
            <p>
              Google Analytics是Google提供的网络分析服务，用于跟踪和报告网站流量。Google使用收集的数据来跟踪和监控我们服务的使用情况。这些数据与其他Google服务共享。Google可能使用收集的数据来contextualize和个性化其自身广告网络的广告。
            </p>
            <p>
              有关Google隐私做法的更多信息，请访问Google隐私条款网页：<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>。
            </p>
            <p>
              我们还鼓励您查看Google保护您数据的政策：<a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>。
            </p>
            <h5>Firebase</h5>
            <p>Firebase是Google Inc.提供的分析服务。</p>
            <p>
              有关Firebase收集的信息类型的更多信息，请访问Google隐私条款网页：<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>。
            </p>
            <h5>Mixpanel</h5>
            <p>Mixpanel由Mixpanel Inc.提供。</p>
            <p>
              您可以通过选择退出来防止Mixpanel将您的信息用于分析目的。要选择退出Mixpanel服务，请访问此页面：<a href="https://mixpanel.com/optout/">https://mixpanel.com/optout/</a>。
            </p>
            <p>
              有关Mixpanel收集的信息类型的更多信息，请访问Mixpanel的使用条款页面：<a href="https://mixpanel.com/terms/">https://mixpanel.com/terms/</a>。
            </p>
          </li>
          <li>
            <h4>CI/CD工具</h4>
            <p>我们可能使用第三方服务提供商来自动化我们服务的开发过程。</p>
            <h5>GitHub</h5>
            <p>GitHub由GitHub, Inc.提供。GitHub是一个开发平台，用于托管和审查代码、管理项目和构建软件。</p>
            <p>
              有关GitHub收集哪些数据、用于什么目的以及如何确保数据保护的更多信息，请访问GitHub隐私政策页面：
              <a href="https://help.github.com/en/articles/github-privacy-statement"> https://help.github.com/en/articles/github-privacy-statement。</a>
            </p>
            <h5>GitLab CI/CD</h5>
            <p>
              GitLab CI/CD由GitLab, Inc.提供。GitLab CI（持续集成）服务是GitLab的一部分，每当开发人员推送代码到应用程序时，它就会构建和测试软件。GitLab CD（持续部署）是一种软件服务，它将每个代码的更改放置在生产中，从而导致每天部署生产。
            </p>
          </li>
          <li>
            <h4>广告</h4>
            <p>我们可能使用第三方服务提供商向您展示广告，以帮助支持和维护我们的服务。</p>
            <h5>AdMob by Google</h5>
            <p>
              AdMob by Google由Google Inc.提供。您可以按照Google描述的说明选择退出AdMob by Google服务：
              <a href="https://support.google.com/ads/answer/2662922?hl=en"> https://support.google.com/ads/answer/2662922?hl=en。</a>
              有关Google如何使用收集的信息的更多信息，请访问"当您使用我们的合作伙伴网站或应用程序时Google如何使用数据"页面：
              <a href="http://www.google.com/policies/privacy/partners/"> http://www.google.com/policies/privacy/partners/</a>或访问Google的隐私政策：
              <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>。
            </p>
            <h5>Google AdSense DoubleClick Cookie</h5>
            <p>
              Google作为第三方供应商，使用cookies在我们的服务上投放广告。Google使用DoubleClick cookie使其及其合作伙伴能够根据我们的用户访问我们的服务或互联网上的其他网站的情况向我们的用户投放广告。您可以通过访问Google广告设置网页选择退出使用DoubleClick Cookie进行基于兴趣的广告：<a href="http://www.google.com/ads/preferences/"> http://www.google.com/ads/preferences/</a>
            </p>
            <h5>AdButler</h5>
            <p>
              AdButler是由Sparklit Networks Inc.提供的广告服务。有关AdButler的更多信息，请访问他们的隐私政策：
              <a href="https://www.sparklit.com/agreements.spark?agreement=privacy">https://www.sparklit.com/agreements.spark?agreement=privacy</a>
            </p>
          </li>
          <li>
            <h4>支付</h4>
            <p>
              我们可能在服务内提供付费产品和/或服务。在这种情况下，我们使用第三方服务进行支付处理（例如支付处理器）。我们不会存储或收集您的支付卡详细信息。该信息直接提供给我们的第三方支付处理器，其使用您的个人信息受其隐私政策管理。这些支付处理器遵守PCI-DSS标准，该标准由PCI安全标准委员会管理，是Visa、Mastercard、American Express和Discover等品牌的共同努力。PCI-DSS要求有助于确保支付信息的安全处理。我们合作的支付处理器有：
            </p>
            <h5>PayPal或Braintree：</h5>
            <p>
              他们的隐私政策可以在<a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">https://www.paypal.com/webapps/mpp/ua/privacy-full</a>查看
            </p>
            <h5>Apple Store应用内支付：</h5>
            <p>
              他们的隐私政策可以在以下网址查看：<a href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a> /
              <a href="https://support.apple.com/en-us/HT203027 ">https://support.apple.com/en-us/HT203027 </a>
            </p>
            <h5>Google Play应用内支付：</h5>
            <p>
              他们的隐私政策可以在以下网址查看：<a href="https://policies.google.com/privacy?hl=en&gl=us">https://policies.google.com/privacy?hl=en&gl=us</a>/
              <a href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en">https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en</a>
            </p>
            <h5>Stripe：</h5>
            <p>
              他们的隐私政策可以在以下网址查看：<a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
            </p>
          </li>
          <li>
            <h4>其他网站的链接</h4>
            <p>
              我们的服务可能包含指向非我们运营的其他网站的链接。如果您点击第三方链接，您将被引导到该第三方的网站。我们强烈建议您查看您访问的每个网站的隐私政策。我们无法控制任何第三方网站或服务的内容、隐私政策或做法，也不承担任何责任。
            </p>
          </li>
          <li>
            <h4>儿童隐私</h4>
            <p>
              我们的服务不适用于18岁以下的儿童（"儿童"）。我们不会故意收集18岁以下儿童的个人身份信息。如果您发现儿童向我们提供了个人数据，请联系我们。如果我们发现我们在未经父母同意的情况下收集了儿童的个人数据，我们会采取措施从我们的服务器上删除该信息。
            </p>
          </li>
          <li>
            <h4>本隐私政策的变更</h4>
            <p>
              我们可能会不时更新我们的隐私政策。我们将通过在此页面上发布新的隐私政策来通知您任何变更。在变更生效之前，我们将通过电子邮件和/或在我们的服务上发布显著通知，并更新本隐私政策顶部的"生效日期"。建议您定期查看本隐私政策以了解任何变更。本隐私政策的变更在此页面上发布时生效。
            </p>
          </li>
          <li>
            <h4>联系我们</h4>
            <p>
              请发送您的反馈、评论、技术支持请求：通过电子邮件：info@metamoda.co。通过访问我们网站上的此页面：<a href="https://cn.metamoda.co">https://cn.metamoda.co</a>。
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};
export default Privacy;
