import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation } from "react-router-dom";

const LandingFooter = ({ setError }) => {
  let location = useLocation();
  let textColor = "text-dark";
  let bgColor = "bg-light";

  if (location.pathname === "/") {
    textColor = "text-light";
    bgColor = "bg-black";
  }

  return (
    <div>
      <Container fluid className={`justify-content-center ${textColor} ${bgColor}`}>
        <Row className="py-3 small td-none">
          <Col lg={{ span: 4, offset: 0 }} xs={{ span: 8, offset: 2 }} className="">
            © 2024 麦塔莫达
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingFooter;
