import React from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CollectionList from "./CollectionList";

const CollectionModal = ({ setError, show, onHide, itemSku }) => {
  const item = CollectionList().find((i) => i.sku === itemSku);

  return (
    <div>
      <Modal show={show} onHide={onHide} size="xl" aria-labelledby="collectionItem" centered>
        <Modal.Body className="bg-light">
          <Container fluid className="bg-light" style={{ height: "550px" }}>
            <Row>
              <Col xs={{ span: 7, offset: 0 }} className={"h-100 mx-0 px-0"}>
                <img src={item.image} className="" alt={item.sku} style={{ height: "550px", width: "100%", objectFit: "cover" }} />
              </Col>
              <Col xs={{ span: 4, offset: 0 }} className={"h-100 ms-5 "}>
                <Row>
                  <Col className="my-5 lead fw-bold text-center">{item.title}</Col>
                </Row>
                <Row>
                  <Col className="my-2 text-start lead">
                    <span className="fw-bold">成分：</span> {item.compositions}
                  </Col>
                </Row>
                <Row>
                  <Col className="my-2 text-start lead">
                    <span className="fw-bold">重量：</span> {item.weights}
                  </Col>
                </Row>
                <Row>
                  <Col className="my-2 text-start lead">
                    <span className="fw-bold">纱线：</span> {item.yarns}
                  </Col>
                </Row>
                <Row>
                  <Col className="my-2 text-start lead">
                    <span className="fw-bold">设计：</span> {item.designs}
                  </Col>
                </Row>
                <Row>
                  <Col className="my-2 text-start lead">
                    <span className="fw-bold">颜色：</span> {item.colours}
                  </Col>
                </Row>
                <Row>
                  <Col className="my-5 text-center lead">
                    <span className="fw-bold">提供定制质量和设计服务</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CollectionModal;