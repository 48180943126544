import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../queries";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const LoginForm = ({ setError, show, onHide }) => {
  const [token, setToken] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [login, result] = useMutation(LOGIN, {
    onError: (error) => {
      setError(error.graphQLErrors[0].message);
    },
  });

  useEffect(() => {
    if (result.data) {
      const token = { value: result.data.login.value, expiry: Date.now() + 600000000 };
      const admin = { value: result.data.login.isAdmin, expiry: Date.now() + 600000000 };
      setToken(token.value);
      setIsAdmin(admin.value);
      localStorage.setItem("stf-user-token", JSON.stringify(token));
      localStorage.setItem("stf-user-isAdmin", JSON.stringify(admin));
      window.location.href = "/";
    }
  }, [result.data]); // eslint-disable-line

  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await login({ variables: { username, password } });
    setLoading(false);
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size="md" aria-labelledby="Login" centered className="text-center mx-auto my-auto py-5 vw-50">
        <Modal.Body className="bg-light text-dark">
          <Container>
            <Row>
              <Col>
                <Form onSubmit={submit} className="text-center mx-auto my-auto py-5">
                  <Row>
                    <Col>
                      <div className="w-50 py-1 mx-auto">
                        <img src="/metamodalogo.png" alt="Metamoda Logo" className="w-100"></img>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="w-50 py-1 mx-auto" controlId="loginUser">
                        <Form.Control className="bg-light text-dark" placeholder="用户名" value={username} onChange={({ target }) => setUsername(target.value)} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="w-50 py-1 mx-auto" controlId="loginPass">
                        <Form.Control className="bg-light text-dark" placeholder="密码" type="password" value={password} onChange={({ target }) => setPassword(target.value)} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button className="mx-auto py-1" variant="outline-dark" size="sm" type="submit" disabled={isLoading}>
                        {isLoading ? "加载中..." : "登录"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginForm;
