import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation } from "react-router-dom";

const LandingLogo = ({ setError }) => {
  let location = useLocation();
  let textColor = "text-dark";

  if (location.pathname === "/") {
    textColor = "text-grey";
  }

  return (
    <div>
      <Container fluid className="position-absolute text-start top-0 start-0" style={{ zIndex: 1000 }}>
        <Container className="m-0 p-0" fluid>
          <Row xs={1}>
            <Col xs={{ span: 7, offset: 1 }} sm={{ span: 7, offset: 0 }}>
              <Link className="text-decoration-none metamoda-font" to="/">
                <Row>
                  <Col>
                    <span className={`display-3 text-decoration-none ${textColor} metamoda-font`}>麦塔莫达 | METAMODA</span>
                  </Col>
                </Row>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default LandingLogo;
