const CollectionList = () => {
  const collection = [
    {
      sku: "MET",
      title: "羊毛",
      compositions: "50% 羊毛至 100% 羊毛",
      weights: "190克/米 至 400克/米",
      yarns: "14.5微米 至 21微米",
      designs: "库存100多种设计",
      colours: "库存500多种颜色",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/METHero.JPG",
    },
    {
      sku: "GEC",
      title: "衬衫面料",
      compositions: "95% 棉至 100% 棉",
      weights: "86克/米 至 300克/米",
      yarns: "60支 至 120支双股",
      designs: "库存50多种设计",
      colours: "库存200多种颜色",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/GECHero.JPG",
    },
    {
      sku: "AMA",
      title: "斜纹棉布",
      compositions: "50% 棉 / 50% 莫代尔 至 100% 棉",
      weights: "150克/米 至 250克/米",
      yarns: "60支 至 100支",
      designs: "仅纯色",
      colours: "库存150多种颜色",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/AMAHero.JPG",
    },
    {
      sku: "JER",
      title: "针织面料",
      compositions: "70% 棉 至 100% 棉",
      weights: "190克/米 至 400克/米",
      yarns: "60支 至 100支",
      designs: "仅纯色",
      colours: "库存100多种颜色",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/JERHero.JPG",
    },
    {
      sku: "550",
      title: "纺纱面料",
      compositions: "100% 纺纱长丝",
      weights: "100克/米 至 120克/米",
      yarns: "细纺纱长丝",
      designs: "仅纯色",
      colours: "即将推出15种颜色",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/550Hero.JPG",
    },
  ];

  return collection;
};
export default CollectionList;