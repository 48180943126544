import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ContactForm from "../ContactForm";

const LandingContact = ({ setError }) => {
  const [waitlistModalShow, setWaitlistModalShow] = useState(false);

  return (
    <div>
      <Container fluid className="py-5 text-dark bg-light min-vh-100">
        <Row className="align-items-center mt-5">
          <Col xs={{ span: 10, offset: 1 }} className="text-start">
            <Row className="my-5 pt-5">
              <Col className="display-6">
                <AnimationOnScroll
                  duration={2}
                  animateOnce={true}
                  animateIn="animate__fadeIn"
                >
                  联系我们 | Contact Us
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col
            xs={{ span: 8, offset: 2 }}
            sm={{ span: 6, offset: 4 }}
            className=" text-end lead"
          >
            <Row className="mt-5 mb-2">
              <Col>
                <AnimationOnScroll
                  duration={2}
                  animateOnce={true}
                  delay={250}
                  animateIn="animate__fadeIn"
                >
                  <div>麦塔莫达贸易（苏州）有限公司</div>
                  <div>Metamoda Trading (Su Zhou) Co., Ltd</div>
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="mt-2 mb-5">
              <Col>
                <AnimationOnScroll
                  duration={2}
                  animateOnce={true}
                  delay={250}
                  animateIn="animate__fadeIn"
                >
                  <div>
                    江苏省张家港市塘桥镇庙桥吹鼓路99号菱锐智能制造产业园23号楼3层
                  </div>
                  <div>
                    Level 3 Building 23, Lingrui Intelligent Manufacturing
                    Industrial Park, No. 99, Miaoqiao Chuigu Road, Tangqiao
                    Town, Zhangjiagang City, Jiangsu Province - China
                  </div>
                  <div>
                    <a
                      href="https://j.map.baidu.com/8a/iMzh"
                      target="_blank"
                      rel="noreferrer"
                    >
                      地图 | Map
                    </a>
                  </div>
                  <div>13962285225</div>
                </AnimationOnScroll>
              </Col>
            </Row>

            <Row className="my-5">
              <Col>
                <AnimationOnScroll
                  duration={2}
                  animateOnce={true}
                  delay={250}
                  animateIn="animate__fadeIn"
                >
                  <div>
                    您可以通过 info@metamoda.co 联系我们，或点击下方按钮。
                  </div>
                  <div>
                    You can reach us at info@metamoda.co, or by clicking the
                    button below.
                  </div>
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll
                  duration={2}
                  animateOnce={true}
                  delay={500}
                  animateIn="animate__fadeIn"
                >
                  <div>我们将尽最大努力尽快回复您。</div>
                  <div>
                    We will make every effort to respond to you as soon as
                    possible.
                  </div>
                </AnimationOnScroll>
              </Col>
            </Row>

            <Row className="mb-5">
              <Col
                xs={{ span: 6, offset: 3 }}
                sm={{ span: 6, offset: 6 }}
                className="align-items-end text-end"
              >
                <AnimationOnScroll
                  duration={2}
                  animateOnce={true}
                  delay={750}
                  animateIn="animate__fadeIn"
                >
                  <Button
                    onClick={() => setWaitlistModalShow(true)}
                    size="lg"
                    className="my-3"
                    variant="outline-dark"
                  >
                    联系 | Contact
                  </Button>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ContactForm
        show={waitlistModalShow}
        onHide={() => setWaitlistModalShow(false)}
        setError={setError}
      />
    </div>
  );
};

export default LandingContact;
