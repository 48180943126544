import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const LegalDisclaimerModal = ({ setError }) => {
  const [showModal, setShowModal] = useState(true);
  const disclaimer = `麦塔莫达使用cookies来增强您与我们的体验。我们绝不会分享或出售您的数据。`;

  if (showModal) {
    return (
      <div>
        <Container fluid className=" mx-0 my-0 py-2 small position-fixed bottom-0 vw-100 bg-dark text-light" style={{ zIndex: 100000 }}>
          <Row>
            <Col xs={10}>{disclaimer}</Col>
            <Col>
              <Row>
                <Col>
                  <Link to="/legal">
                    <Button className="mx-auto" variant="outline-light" size="sm">
                      法律声明
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Button
                    className="mx-auto"
                    variant="outline-light"
                    size="sm"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    我已了解
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  return <div></div>;
};

export default LegalDisclaimerModal;